<template>
   <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'App',
  components: {
    
  }
});
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
 
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: #212832;

  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
