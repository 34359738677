/* 路由配置文件 index.ts */

import { createWebHashHistory, createRouter } from 'vue-router';

// 定义路由配置
const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home.vue'),
  },
];

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(), // 导航历史记录模式
  routes,
});

// 导出实例
export default router;
