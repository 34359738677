import { createApp } from 'vue';
import App from './App.vue';

import '@/styles/nomal.css';
import '@/styles/global.less';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from '@/router';
let app = createApp(App);
import customDialog from '@/components/customDialog.vue'; // svg组件
app.component('customDialog', customDialog);
import dbInput from '@/components/dbInput.vue'; // svg组件
app.component('dbInput', dbInput);
import shrinkBox from '@/components/shrinkBox.vue'; // svg组件
app.component('shrinkBox', shrinkBox);
import toolTip from '@/components/toolTip.vue'; // svg组件
app.component('toolTip', toolTip);
import SvgIcon from '@/components/svgIcon/index.vue'; // svg组件
app.component('svgIcon', SvgIcon);
// import './assets/icons'; // 让所有图标通过 SVG Sprite 方式加载
import '@/assets/icons/index'; // svg

import * as ElIcons from '@element-plus/icons-vue';
// 全局注册element-plus icon图标组件
Object.keys(ElIcons).forEach((key) => {
  //循环遍历组件名称
  if ('Menu' !== key) {
    //如果不是图标组件不是Menu，就跳过，否则加上ICon的后缀
    app.component(key, ElIcons[key as keyof typeof ElIcons]);
  } else {
    app.component(key + 'Icon', ElIcons[key]); //MenuIcon
  }
});
app.use(ElementPlus);
app.use(router);
app.mount('#app');

window.oncontextmenu = function () {
  return false;
};
