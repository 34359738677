<template>
  <svg
    aria-hidden="true"
    class="svg-icon"
    :style="{ width: width, height: height }"
  >
    <use
      :xlink:href="symbolId"
      :fill="svgColor"
    />
  </svg>
</template>

<script setup lang="ts">
// 使用:<SvgIcon name="vue" color="pink" width="100px" height="100px"></SvgIcon> //vue必须在assets/icons下有对应的svg文件
import { computed, defineProps } from 'vue'
const props = defineProps({
  prefix: {
    type: String,
    default: 'icon'
  },
  name: {
    type: String,
    required: true
  },
  color: {
    type: String,
    default: '#57b8ff'
  },
  width: {
    type: String,
    default: '22px'
  },
  height: {
    type: String,
    default: '22px'
  }
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
const svgColor = computed(() => `${props.color}`)
</script>
<style scoped>
.sub-el-icon,
.nav-icon {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  font-size: 15px;
}

.svg-icon {
  position: relative;
  width: 1em;
  height: 1em;
  vertical-align: -2px;
  fill: currentcolor;
}
</style>
