import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href", "fill"]

import { computed } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  prefix: {
    type: String,
    default: 'icon'
  },
  name: {
    type: String,
    required: true
  },
  color: {
    type: String,
    default: '#57b8ff'
  },
  width: {
    type: String,
    default: '22px'
  },
  height: {
    type: String,
    default: '22px'
  }
},
  setup(__props) {

// 使用:<SvgIcon name="vue" color="pink" width="100px" height="100px"></SvgIcon> //vue必须在assets/icons下有对应的svg文件
const props = __props

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
const svgColor = computed(() => `${props.color}`)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    "aria-hidden": "true",
    class: "svg-icon",
    style: _normalizeStyle({ width: __props.width, height: __props.height })
  }, [
    _createElementVNode("use", {
      "xlink:href": symbolId.value,
      fill: svgColor.value
    }, null, 8, _hoisted_1)
  ], 4))
}
}

})