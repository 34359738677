<template>
  <div class="shrink-box">
    <div class="pa-1 shrink-title-box">
      <div class="shrink-title">
        {{title}}
      </div>
      <div
        class="shrink-icon"
        @click.stop="changeShrink"
      >
        <el-icon>
          <Minus v-if="showShrink" />
          <Plus v-else />
        </el-icon>
      </div>
    </div>
    <div
      class="shrink-main animate__animated animate__fadeInBottomBig"
      v-if="showShrink"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from 'vue'
const props = defineProps({
  title: {
    type: String,
    default: ''
  }
}),
  showShrink = ref(true)
/**
 * 改变
 */
function changeShrink () {
  showShrink.value = !showShrink.value
}
</script>


<style lang='less' scoped>
.shrink-box {
  margin-top: 12px;
  .shrink-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    width: 100%;
    background: #212832;
    i {
      cursor: pointer;
    }
  }
}
</style>