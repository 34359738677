<template>
  <div class="db-input">
    <div
      v-if="!editMode"
      class="db-value"
      @dblclick="changeInput"
      :style="{'background':bg}"
    >
      <div class="scroll-y">
        {{inputVal}}
      </div>
    </div>
    <input
      v-else
      ref="dbInput"
      :value="inputVal"
      @input="handleInput"
      :placeholder="placeholder"
      @blur="editMode = false"
      class="db-values"
    />
  </div>

</template>
  <script setup>
import { ref, defineProps, defineEmits, nextTick, onBeforeUnmount, watch } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'num'
  },
  bg: {
    type: String,
    default: 'transpranet'
  }
})
let editMode = ref(false),
  dbInput = ref(null),
  inputVal = ref('')
function changeInput () {
  editMode.value = true
  nextTick(() => {
    setTimeout(() => {
      dbInput.value.focus()
    }, 50);
  })

}
function handleInput (event) {
  let value = event.target.value
  if (props.type && props.type.indexOf('um') > -1) {
    let chageVal = checkGreaterZero(value)
    value = chageVal || props.modelValue
    inputVal.value = value
  }
  console.log("handleInput", props.type && props.type.indexOf('umbe') > -1, value);
  emit('update:modelValue', value);
}
/**
 * 整型
 */
function checkGreaterZero (value) {
  // 使用正则表达式匹配和/或替换非法字符
  return value.replace(/[^\-?\d.]/g, "")
}
watch(
  () => props.modelValue,
  (val, oldVal) => {
    inputVal.value = ''
    if ((val || val == 0) && oldVal != val) {
      inputVal.value = JSON.parse(JSON.stringify(val))
    }
  },
  {
    immediate: true
  }
)
onBeforeUnmount(() => {
  editMode.value = false
})
  </script>
  <style lang="less" scoped>
.db-input {
  width: 100%;
  height: 32px;
  .db-value {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 2px 0 11px;
    border-radius: 4px;
    .scroll-y {
      height: auto;
      max-height: 100%;
    }
  }
  .db-values {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: none;
    padding: 1px 11px;
    box-sizing: border-box;
    outline: none;
    color: #fff;
    background: @main-bg;
  }
}
</style>